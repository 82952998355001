import React from 'react';

import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface PricingCardProps {
  Icon: React.ElementType;
  title: string;
  number: number;
  text: string;
}

const PricingCard: React.FC<PricingCardProps> = ({ Icon, title, number, text }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      whileHover={{ scale: 1.05 }}
      className='w-full shadow-xl flex flex-col p-4 my-4 rounded-lg bg-[#D8ECE1] text-[#f84831]'
    >
      <div className='w-20 h-20 mx-auto mt-[-3rem] bg-[#D8ECE1] rounded-full flex items-center justify-center overflow-hidden'>
        <Icon style={{ fontSize: '4rem', color: '#f84831' }} />
      </div>
      <p className='text-center text-4xl font-bold'>{number}</p>
      <h2 className='text-3xl leading-relaxed font-bold text-center py-4'>{title}</h2>
      <div className='text-center font-medium'>
        <p className='text-xl font-semibold max-w-xl mx-auto leading-relaxed py-1 mt-8'>{text}</p>
      </div>
    </motion.div>
  );
};

const Works: React.FC = () => {
  const { t } = useTranslation();

  const workInfoData = [
    {
      Icon: PermPhoneMsgIcon,
      title: t("functionality.parts.first.title"),
      text: t("functionality.parts.first.text"),
    },
    {
      Icon: PeopleAltIcon,
      title: t("functionality.parts.second.title"),
      text: t("functionality.parts.second.text"),
    },
    {
      Icon: DoneAllIcon,
      title: t("functionality.parts.third.title"),
      text: t("functionality.parts.third.text"),
    },
  ];

  return (
    <div className='w-full py-[7rem] px-4 bg-[#f84831] text-center'>
      <h1 className='text-6xl font-extrabold mb-2 leading-none tracking-tighter text-[#D8ECE1]'>{t('functionality.title')}</h1>
      <div className="mx-auto h-1 w-24 bg-[#F1F5EA] mb-16"></div>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-3 gap-12'>
        {workInfoData.map((item, index) => (
          <PricingCard key={index} number={index + 1} Icon={item.Icon} title={item.title} text={item.text} />
        ))}
      </div>
    </div>
  );
};

export default Works;
