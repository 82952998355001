import React from "react";
import { ReactComponent as PeruIcon } from "../../../assets/countries/peru.svg";
import { ReactComponent as ChileIcon } from "../../../assets/countries/chile.svg";
import { ReactComponent as UKIcon } from "../../../assets/countries/uk.svg";
import { ReactComponent as MexicoIcon } from "../../../assets/countries/mexico.svg";
import { ReactComponent as USAIcon } from "../../../assets/countries/usa.svg";
import { ReactComponent as RussiaIcon } from "../../../assets/countries/russia.svg";
import { ReactComponent as ChinaIcon } from "../../../assets/countries/china.svg";
import { ReactComponent as NetherlandsIcon } from "../../../assets/countries/netherlands.svg";
import { ReactComponent as SpainIcon } from "../../../assets/countries/spain.svg";
import { ReactComponent as KoreaIcon } from "../../../assets/countries/korea.svg";
import { ReactComponent as MoroccoIcon } from "../../../assets/countries/morocco.svg";
import { ReactComponent as ItalyIcon } from "../../../assets/countries/italy.svg";
import { ReactComponent as CanadaIcon } from "../../../assets/countries/canada.svg";
import { ReactComponent as CostaRicaIcon } from "../../../assets/countries/costaRica.svg";
import { ReactComponent as ElSalvadorIcon } from "../../../assets/countries/elSalvador.svg";
import { ReactComponent as BelizeIcon } from "../../../assets/countries/belize.svg";
import { ReactComponent as HondurasIcon } from "../../../assets/countries/honduras.svg";
import { ReactComponent as NicaraguaIcon } from "../../../assets/countries/nicaragua.svg";
import { ReactComponent as PanamaIcon } from "../../../assets/countries/panama.svg";
import { ReactComponent as BahamasIcon } from "../../../assets/countries/bahamas.svg";
import { ReactComponent as CurazaoIcon } from "../../../assets/countries/curazao.svg";
import { ReactComponent as StEustaquioIcon } from "../../../assets/countries/stEustaquio.svg";
import { ReactComponent as TrinidadTobagoIcon } from "../../../assets/countries/trinidadTobago.svg";

import { useTranslation } from "react-i18next";

// Color constants
const PRIMARY_COLOR = "#f84831";
const BACKGROUND_COLOR = "#D8ECE1";
const HOVER_BACKGROUND_COLOR = "hover:bg-[#ECEEE9]"; // Using Tailwind class for hover effect

interface Country {
  id: number;
  name: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  location: string;
}

interface CountryBlockProps {
  country: Country;
}

const CountryBlock: React.FC<CountryBlockProps> = ({ country }) => {
  const Icon = country.icon;
  return (
    <div className={`flex flex-col items-center p-4 rounded-lg ${HOVER_BACKGROUND_COLOR} transition-shadow duration-300 ease-in-out`}>
      <Icon style={{ fill: PRIMARY_COLOR, width: "96px", height: "96px" }} />
      <p className="mt-3 text-lg font-semibold" style={{ color: PRIMARY_COLOR }}>
        {country.name}
      </p>
    </div>
  );
};

const Locations: React.FC = () => {
  const { t } = useTranslation();

  const countries: Country[] = [
    // South America
    { id: 1, name: t("locations.peruName"), icon: PeruIcon, location: "Peru" },
    { id: 2, name: t("locations.chileName"), icon: ChileIcon, location: "Chile" },

    // North America & Central America
    { id: 3, name: t("locations.mexicoName"), icon: MexicoIcon, location: "Mexico" },
    { id: 21, name: t("locations.canadaName"), icon: CanadaIcon, location: "Canada" },
    { id: 13, name: t("locations.belizeName"), icon: BelizeIcon, location: "Belize" },
    { id: 14, name: t("locations.hondurasName"), icon: HondurasIcon, location: "Honduras" },
    { id: 15, name: t("locations.nicaraguaName"), icon: NicaraguaIcon, location: "Nicaragua" },
    { id: 16, name: t("locations.panamaName"), icon: PanamaIcon, location: "Panama" },
    { id: 22, name: t("locations.costaricaName"), icon: CostaRicaIcon, location: "Costa Rica" },
    { id: 23, name: t("locations.elsalvadorName"), icon: ElSalvadorIcon, location: "El Salvador" },

    // Caribbean
    { id: 17, name: t("locations.bahamasName"), icon: BahamasIcon, location: "Bahamas" },
    { id: 18, name: t("locations.curazaoName"), icon: CurazaoIcon, location: "Curazao" },
    { id: 19, name: t("locations.steustaquioName"), icon: StEustaquioIcon, location: "St. Eustaquio" },
    { id: 20, name: t("locations.trinidadTobagoName"), icon: TrinidadTobagoIcon, location: "Trinidad & Tobago" },

    // Europe
    { id: 4, name: t("locations.ukName"), icon: UKIcon, location: "UK" },
    { id: 7, name: t("locations.netherlandsName"), icon: NetherlandsIcon, location: "Netherlands" },
    { id: 8, name: t("locations.italyName"), icon: ItalyIcon, location: "Italy" },
    { id: 9, name: t("locations.spainName"), icon: SpainIcon, location: "Spain" },

    // Africa
    { id: 10, name: t("locations.moroccoName"), icon: MoroccoIcon, location: "Morocco" },

    // Asia
    { id: 11, name: t("locations.chinaName"), icon: ChinaIcon, location: "China" },
    { id: 12, name: t("locations.koreaName"), icon: KoreaIcon, location: "Korea" },

    // Others
    { id: 5, name: t("locations.usaName"), icon: USAIcon, location: "USA" },
    { id: 6, name: t("locations.russiaName"), icon: RussiaIcon, location: "Russia" }
  ];

  return (
    <div className="py-[7rem] px-4" style={{ backgroundColor: BACKGROUND_COLOR, color: PRIMARY_COLOR }}>
      <div className="text-center">
        <h1 className="text-6xl font-extrabold mb-2">{t("locations.title")}</h1>
        <div className="inline-block h-1 w-24 bg-primary mb-4" style={{ backgroundColor: PRIMARY_COLOR }}></div>
        <p className="text-xl font-semibold mb-8 max-w-xl mx-auto">{t("locations.description")}</p>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
        {countries.map((country) => (
          <CountryBlock key={country.id} country={country} />
        ))}
      </div>
    </div>
  );
};

export default Locations;
