import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Principal from './components/principal/Principal';
import InspectorRegistrationForm from './components/inspector/register/InspectorRegistrationForm';
import './translations/i18n';
import { I18nextProvider, useTranslation } from 'react-i18next';
import browserLang from 'browser-lang';
import { LOCAL_ROUTES } from './misc/constants';

function App() {
  const { i18n } = useTranslation();

  useEffect(() => {
    const detectedLanguage = browserLang({
      languages: ['en', 'es'],
      fallback: 'en',
    });

    // Change language in i18n if detected language is different from current language
    if (detectedLanguage !== i18n.language) {
      i18n.changeLanguage(detectedLanguage);
    }
  }, [i18n]);

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route path={LOCAL_ROUTES.HOME} element={<Principal />} />
          <Route path="*" element={<Navigate to={LOCAL_ROUTES.HOME} replace />} />
        </Routes>
      </Router>
    </I18nextProvider>
  );
}

export default App;
