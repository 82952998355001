import React, { useRef, useEffect, ReactNode } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';
import Network from './parts/Network';
import Cards from './parts/Cards';
import Footer from './parts/Footer';
import Intro from './parts/Intro';
import Navbar from './parts/Navbar';
import Locations from './parts/Locations';
import Contact from './parts/Contact';
import InspectSet from './parts/InspectSet';

// Define the props for AnimatedSection
interface AnimatedSectionProps {
  children: ReactNode;
  delay?: number;
}

// Reusable AnimatedSection component
const AnimatedSection: React.FC<AnimatedSectionProps> = ({ children, delay = 0 }) => {
  const controls = useAnimation();
  const ref = useRef<HTMLDivElement | null>(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start('visible');
    }
  }, [controls, isInView]);

  const variants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: 'spring',
        duration: 1,
        delay,
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={controls}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

const Principal: React.FC = () => {
  const contactRef = useRef<HTMLDivElement | null>(null); // Create a ref
  
  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <Navbar onClickContactUS={scrollToContact}/> 
      <AnimatedSection>
        <Intro onClickGetStarted={scrollToContact}/> 
      </AnimatedSection>
      <AnimatedSection delay={0.2}>
        <Network onClickGetStarted={scrollToContact} />
      </AnimatedSection>
      <AnimatedSection delay={0.2}>
        <Cards />
      </AnimatedSection>
      <AnimatedSection delay={0.2}>
        <Locations />
      </AnimatedSection>
      <AnimatedSection delay={0.4}>
        <InspectSet />
      </AnimatedSection>
      <AnimatedSection delay={0.2}>
        <div ref={contactRef}>
          <Contact />
        </div>
      </AnimatedSection>
      <Footer />
    </div>
  );
}

export default Principal;