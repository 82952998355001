import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface TypistEffectProps {
  text: string;
  typingSpeed?: number;
  deletingSpeed?: number;
  className?: string;
}

const TypistEffect: React.FC<TypistEffectProps> = ({ text, typingSpeed = 77, deletingSpeed = 70, className = '' }) => {
  const [displayedText, setDisplayedText] = useState<string>(text.charAt(0)); // Initialize with the first character
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(1);
  const [typingDelay, setTypingDelay] = useState<number>(typingSpeed);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!isDeleting && index < text.length) {
      timeoutId = setTimeout(() => {
        setDisplayedText((displayedText) => displayedText + text.charAt(index));
        setIndex(index + 1);
      }, typingDelay);
    } else if (isDeleting && index > 1) { // Ensure it stops deleting when it reaches the first character
      timeoutId = setTimeout(() => {
        setDisplayedText((displayedText) => displayedText.slice(0, index - 1));
        setIndex(index - 1);
      }, deletingSpeed);
    } else if (isDeleting) {
      setIsDeleting(false);
      // Reset to start typing from the second character again
      setIndex(1);
      setDisplayedText(text.charAt(0)); // Reset to only the first character
      setTypingDelay(typingSpeed);
    } else {
      setIsDeleting(true);
      // Ensure deletion stops before the first character
      setTypingDelay(deletingSpeed);
    }

    return () => clearTimeout(timeoutId);
  }, [index, isDeleting, text, typingSpeed, deletingSpeed, typingDelay]);

  return <span className={className}>{displayedText}</span>;
};

interface HeroProps {
  onClickGetStarted: () => void;
}

const Hero: React.FC<HeroProps> = ({ onClickGetStarted }) => {
  const { t } = useTranslation();

  return (
    <div className='bg-[#f84831] text-[#D8ECE1] py-[7rem]'>
      <div className='max-w-[800px] mx-auto text-center flex flex-col justify-center space-y-6'>
        <h1 className='text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold leading-tight tracking-tighter'>
          {t('intro.title')}
        </h1>
        <div className="mx-auto h-1 w-24 bg-[#D8ECE1] my-3"></div>
        <div className='flex justify-center items-center'>
          <TypistEffect
            text={t('intro.subtitle')}
            typingSpeed={210}
            className='text-2xl sm:text-3xl md:text-4xl font-bold'
          />
        </div>
        <p className='text-lg sm:text-xl md:text-2xl font-bold my-3'>
          {t('intro.description')}
        </p>
        <button className='bg-[#D8ECE1] hover:bg-gray-300 w-[200px] rounded-md font-medium my-5 mx-auto py-2 text-[#f84831] transition-colors duration-300' onClick={onClickGetStarted}>
          {t('getStarted')}
        </button>
      </div>
    </div>
  );
};

export default Hero;