// Base API URL and common path elements
export const BASE_API_URL = 'https://api.surveyfoods.com';
export const HOME_PATH = '/';
export const ROUTE_SEPARATOR = '/';
export const WEB_CLIENT = 'web';

// User roles defined as constants to prevent hardcoding
export const USER_ROLES = {
  ADMIN: 'admin',
  INSPECTOR: 'inspector',
  CLIENT: 'client',
};

// Collection names for consistency with backend database
export const COLLECTIONS = {
  USERS: 'users',
  INSPECTIONS: 'inspections',
  SAMPLES: 'samples',
  TABLES: 'tables',
  PHOTOS: 'photos',
  REFRESH_TOKEN_WHITELIST: 'refresh_token_whitelist',
};

// Token types and names used for authentication
export const TOKEN_TYPES = {
  ACCESS: 'access',
  REFRESH: 'refresh',
};

export const TOKEN_NAMES = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
};

// Common HTTP headers
export const HEADERS = {
  CONTENT_TYPE: 'Content-Type',
  APPLICATION_JSON: 'application/json',
  AUTHORIZATION: 'Authorization',
};

// Common content types used in requests
export const CONTENT_TYPES = {
  APPLICATION_JSON: 'application/json',
};

// Define routes that will be used across the application
export const API_ROUTES = {
  USERS: 'users',
  INSPECTIONS: 'inspections',
  SAMPLES: 'samples',
  TABLES: 'tables',
  PHOTOS: 'photos',
  AUTH: 'auth',
  ADMIN: 'admin',
  INSPECTORS: 'inspectors',
  EMAIL_CODE: 'email-code',
  EMAIL_VALIDATION: 'email-validation',
  TOKEN: 'token',
  REFRESH: 'refresh',
  INITIAL_DATA: 'initial-data',
  EMAIL_REPORT: 'emailReport',
  LOGIN: 'login',
};

// Define actions or common API operation names
export const ACTIONS = {
  REGISTER: 'register',
  LOGIN: 'login',
  SET_EMAIL_CODE: 'setEmailCode',
  SET_EMAIL_CODE_FOR_INSPECTOR: 'setEmailCodeForInspector',
  VALIDATE_EMAIL_CODE: 'validateEmailCode',
  FETCH_INITIAL_USER_DATA: 'fetchInitialUserData',
  REFRESH_TOKEN: 'refreshToken',
  GET_INSPECTION_PREVIEWS: 'getInspectionPreviews',
  SEND_EMAIL_REPORT: 'emailReport',
  SET_COOKIE_HEADER: 'Set-Cookie',
};

// src/constants/constants.js
export const LOCAL_ROUTES = {
  HOME: '/',
  REGISTER_INSPECTOR: '/inspectors/register',
};
