import React from 'react';
import Slider from '../../slider/Slider';
import Blueberries from '../../../assets/photos/blueberries.jpg';
import Mandarins from '../../../assets/photos/mandarins.jpg';
import Palta from '../../../assets/photos/palta.jpg';
import Palta2 from '../../../assets/photos/palta2.jpg';
import Pommegrennate from '../../../assets/photos/pommegrenate.jpg';
import { useTranslation } from 'react-i18next';

// Color constants
const PRIMARY_COLOR = "#f84831";

// Define the props interface
interface NetworkProps {
  onClickGetStarted: () => void; // Function type for the onClick event
}

const Network: React.FC<NetworkProps> = ({ onClickGetStarted }) => {
  const { t } = useTranslation();
  
  const images: string[] = [
    Blueberries,
    Mandarins,
    Palta,
    Palta2,
    Pommegrennate
  ];

  return (
    <div className='w-full bg-[#D8ECE1] py-[7rem] px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2 gap-8 items-center'>
        <div className='flex flex-col justify-center text-center md:text-left md:items-start'>
          <h1 className='text-5xl font-extrabold mb-2 leading-tight tracking-tighter text-[#f84831]'>{t('network.title')}</h1>
          <div className='flex justify-center md:justify-start'>
            <div className="h-1 w-24 bg-primary mb-4" style={{ backgroundColor: PRIMARY_COLOR }}></div>
          </div>
          <p className={`text-xl font-semibold mb-8 max-w-xl mx-auto md:mx-0 leading-relaxed`} style={{ color: PRIMARY_COLOR }}>
            {t('network.description')}
          </p>
          <div className="flex justify-center md:justify-start">
            <button className='bg-[#f84831] text-[#F1F5EA] w-[200px] rounded-md font-medium my-6 py-3 hover:bg-[#f84831]/90 transition-colors duration-300' onClick={onClickGetStarted}>
              {t('network.getStarted')}
            </button>
          </div>
        </div>
        <Slider images={images} autoSlide={true} />
      </div>
    </div>
  );
};

export default Network;
