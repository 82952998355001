import React from 'react';
import { useTranslation } from 'react-i18next';

interface NavbarProps {
  onClickContactUS: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onClickContactUS }) => {
  const { t } = useTranslation();
  const logoURL = "https://www.surveyfoods.com/public/assets/logos/orange_bg_logo.jpeg";

  return (
    <div className='bg-[#D8ECE1]'>
      <div className='flex justify-between items-center h-24 max-w-[1240px] mx-auto px-4 text-white w-full'>
        <img src={logoURL} alt="Logo" className="w-30pct h-auto min-w-[100px] max-w-[300px] sm:w-1/2 md:w-1/3 lg:w-1/4 mr-4" />
        <div className="flex">
          <button
            className='p-4 font-bold text-xl bg-[#f84831] text-[#D8ECE1] rounded hover:bg-gray-400 transition-colors duration-200'
            style={{ fontSize: 'clamp(16px, 2vw, 24px)', minWidth: '100px', whiteSpace: 'nowrap' }}
            onClick={onClickContactUS}
          >
            {t('navbar.contactUs')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
